@import './simplifi-design-compiled.scss';
@import './variables.scss';

// Override elements here
:root {
  --font-sans-serif: 'Selecta', Helvetica, Arial, sans-serif;
  --mat-expansion-header-text-font: #{$simpliFont};
  --mat-menu-item-label-text-font: #{$simpliFont};
  --mat-expansion-container-text-font: #{$simpliFont};
  --mat-tab-header-label-text-font: #{$simpliFont};
  --color-text-secondary: #{$text-secondary};
  --font-weight-bold: #{$fw-bold};
  --mat-tab-header-inactive-label-text-color: #{$text-secondary};
  --mat-expansion-container-text-weight: #{$fw-normal};
  --mdc-plain-tooltip-container-color: #{$tooltip-bg};
  --mdc-plain-tooltip-supporting-text-size: #{$font-xs};
}
@font-face {
  font-family: 'Selecta';
  src:
    url(../../assets/fonts/Selecta/Selecta-Regular.woff) format('woff'),
    url(../../assets/fonts/Selecta/Selecta-Regular.woff2) format('woff2');
  font-weight: 100;
  font-display: 'auto';
  font-style: normal;
}
@font-face {
  font-family: 'Selecta';
  src:
    url(../../assets/fonts/Selecta/Selecta-Medium.woff) format('woff'),
    url(../../assets/fonts/Selecta/Selecta-Medium.woff2) format('woff2');
  font-weight: 120;
  font-display: 'auto';
  font-style: normal;
}

@font-face {
  font-family: 'Selecta';
  src:
    url(../../assets/fonts/Selecta/Selecta-Bold.woff) format('woff'),
    url(../../assets/fonts/Selecta/Selecta-Bold.woff2) format('woff2');
  font-weight: 700;
  font-display: 'auto';
  font-style: normal;
}
@font-face {
  font-family: element-icons;
  src:
    url(../../assets/icons/element-icons.woff) format('woff'),
    url(../../assets/icons/element-icons.ttf) format('truetype');
  font-weight: 400;
  font-display: 'auto';
  font-style: normal;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.max-w-400 {
  max-width: 400px;
}
.min-w-180 {
  min-width: 180px;
}
.is-middle {
  align-items: center !important;
}
.el-form--label-top {
  .el-form-item {
    margin-bottom: 16px !important;
  }
  .el-form-item__label {
    padding-bottom: 3px !important;
    vertical-align: top !important;
    line-height: normal;
  }
}
.el-form-item__label {
  color: $text-secondary;
  line-height: normal;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: $fw-normal;
}
.el-form-item__content {
  position: static !important;
  line-height: normal;
  .el-input__inner {
    border-color: $lt-border-color;
    color: $text-primary;
    font-family: $simpliFont;
    &:focus {
      border-color: var(--input-focus-border);
    }
  }
}
.warning-color {
  color: $warn-color;
}
.danger-color {
  color: #ca2525;
}
.success-color {
  color: $success-color;
}
.d-none {
  display: none !important;
}
.dark-blue-bg {
  background-color: $dark-blue;
}
.dark-green-bg {
  background-color: $dark-green;
}
.cyan-bg {
  background-color: $cyan;
}
.dark-grey-bg {
  background-color: #cecece;
}
.red-bg {
  background-color: #ca2525;
}
.orange-bg {
  background-color: #ff9d00;
}
.color-primary-blue {
  color: #2f6887;
}
.font-xs {
  font-size: 12px;
}
.font-sm {
  font-size: $font-sm;
}
.font-xl {
  font-size: $font-xl;
}
.font-xxl {
  font-size: $font-xxl;
}
.cursor-pointer {
  cursor: pointer;
}

.simpli-button-group {
  .mat-button-toggle {
    background-color: $list-border-color;
    color: $text-primary;
    border: 0 none;
    &.mat-button-toggle-checked {
      background-color: $color-primary;
      color: white;
    }
    .mat-button-toggle-button {
      .mat-button-toggle-label-content {
        height: 32px;
        padding: 6px 7px;
        line-height: normal;
        .mat-pseudo-checkbox {
          display: none;
        }
      }
    }
  }
  ::ng-deep &.is-disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.max-w-320 {
  max-width: 320px;
}
strong,
.strong {
  font-weight: 120;
}
.font-lg {
  font-size: 1.25em;
}

.el-textarea.is-disabled {
  .el-textarea__inner {
    background-color: var(--disabled-fill-base);
  }
}

.el-breadcrumb {
  .el-breadcrumb__item {
    .el-breadcrumb__inner {
      a,
      .is-link {
        font-weight: $fw-normal;
      }
    }
  }
}
.els-header__subtitle,
.els-header__title {
  font-weight: $fw-md;
}
.dialog-main-wrapper .el-dialog__headerbtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
