// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$simplifi-plantool-ui-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$simplifi-plantool-ui-accent: mat.m2-define-palette(
  mat.$m2-pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$simplifi-plantool-ui-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$simplifi-plantool-ui-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $simplifi-plantool-ui-primary,
      accent: $simplifi-plantool-ui-accent,
      warn: $simplifi-plantool-ui-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($simplifi-plantool-ui-theme);

@import 'ag-grid-enterprise/styles/ag-grid.css';
@import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
@import './assets/fonts/icomoon/style.css';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';
@import './app/theme/element-override.scss';

html,
body {
  height: 100%;
  margin: 0;
  min-width: inherit !important;
  font-family: 'Selecta', Helvetica, Arial, sans-serif !important;
  font-weight: 100;
  background-color: $body-bg-color;
  .el-button {
    font-weight: $fw-bold !important;
  }
}
body.nb-theme-default {
  height: 100%;
  margin: 0;
  min-width: inherit !important;
  font-family: 'Selecta', Helvetica, Arial, sans-serif !important;
  font-weight: 100;
  background-color: $body-bg-color;
  overflow: hidden;
  [class*=' el-icon-'],
  [class^='el-icon-'] {
    vertical-align: middle;
  }
}

.nb-theme-default p {
  font-family: 'Selecta', Helvetica, Arial, sans-serif !important;
}
.form-border {
  border: 1px solid $list-border-color;
  width: 100%;
}
.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.text-primary {
  color: $text-primary !important;
}
.text-secondary {
  color: $text-secondary !important;
}

.grey-background {
  background-color: var(--table-header-background-color) !important;
}
.font-sm {
  font-size: 14px !important;
}
.font-wt-500 {
  font-weight: 500 !important;
}
* {
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  // Vertical scroll thumb width
  width: 7px;
  // Horizontal scroll thumb height
  height: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $scroll-color;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: $scroll-hover-color;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Target WebKit browsers (Chrome, Safari, Edge) */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  -moz-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

/* Target Firefox */
input[type='number'] {
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}
.color-primary {
  color: $color-primary !important;
}

.pre-filled {
  .el-form-item__label {
    line-height: 16px !important;
  }
  .el-form-item__content {
    line-height: normal;
    .el-input__prefix,
    .el-input__suffix {
      opacity: 1 !important;
      width: auto;
      min-width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-input--prefix {
      .el-input__inner {
        padding-left: 26px !important;
      }
    }

    .el-input__inner {
      border: 0 none;
      padding: 0;
      height: auto;
      line-height: 20px;
      color: $text-primary !important;
      background-color: transparent !important;
      opacity: 1 !important;
      font-weight: 100;
      &::placeholder {
        color: $text-primary !important;
        font-weight: 100;
      }
    }
  }
  .date-range {
    .el-form-item__content {
      .el-input__prefix {
        display: flex;
        align-items: center;
        justify-content: center;
        .el-input__icon {
          width: auto;
          height: auto;
        }
      }
      .el-input__inner {
        padding-left: 26px !important;
      }
    }
  }
  .el-input {
    .ng-select.ng-select-disabled {
      .ng-select-container {
        border: 0 none;
        padding: 0;
        height: auto;
        line-height: 20px;
        color: $text-primary !important;
        background-color: transparent !important;
        opacity: 1 !important;
        min-height: auto;
        font-weight: $fw-md;
        &::placeholder {
          color: $text-primary !important;
          font-weight: $fw-md;
        }
        .prefix-icon {
          margin-right: 8px;
        }
        .ng-value:first-child {
          margin-left: 0 !important;
          margin-right: 6px;
        }
        .ng-arrow-wrapper {
          display: none;
        }
      }
      &.ng-select-single {
        .ng-value {
          padding-left: 2px !important;
          font-weight: $fw-md;
          .ng-value-label {
            font-weight: $fw-md;
          }
        }
      }
    }
    &.is-disabled {
      .el-input__inner {
        background-color: transparent !important;
      }
    }
  }
}

.el-input {
  &.is-disabled {
    .el-input__inner {
      background-color: transparent !important;
    }
  }
}

.ag-cell-value {
  display: flex;
  align-items: center;
  .ag-group-child-count {
    display: none !important;
  }
}

.custom-spinner {
  .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: $text-primary !important;
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
.custom-tooltip-main {
  z-index: 9;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 1px 4px -2px rgba(black, 0.5);
  -moz-box-shadow: 0px 1px 4px -2px rgba(black, 0.5);
  -webkit-box-shadow: 0px 1px 4px -2px rgba(black, 0.5);
  max-width: 635px;
}

.custom-overlay-main {
  .cdk-overlay-pane {
    border-radius: 4px;
    box-shadow: 0px 1px 4px -2px rgba(black, 0.5);
    -moz-box-shadow: 0px 1px 4px -2px rgba(black, 0.5);
    -webkit-box-shadow: 0px 1px 4px -2px rgba(black, 0.5);
  }
}

.ag-tooltip {
  z-index: 9;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0px 1px 4px -2px rgba(black, 0.5);
  -moz-box-shadow: 0px 1px 4px -2px rgba(black, 0.5);
  -webkit-box-shadow: 0px 1px 4px -2px rgba(black, 0.5);
  max-width: 635px;
  font-family: $simpliFont;
  background-color: $tooltip-bg;
  color: white;
}
.status-draft {
  color: $text-primary;
  background: $draft-color;
  border-radius: 50%;
  padding: 3px;
  font-size: 14px;
}
.status-processing {
  color: $notification-color;
  padding: 3px;
  font-size: 14px;
}
.status-error {
  color: white;
  background: $danger-color;
  border-radius: 50%;
  padding: 3px;
  font-size: 14px;
}
.toast-container .ngx-toastr {
  width: auto !important;
}
.toast-message {
  max-width: 500px;
}

.ai-chat {
  .chat-message-box.is-disabled,
  .chat-message-box.is-disabled:disabled,
  .chat-message-box.is-disabled:focus,
  .chat-message-box.is-disabled:hover,
  textarea:disabled::placeholder,
  textarea:disabled {
    border-color: $disabled-border-base !important;
  }
  .chat-message-box.is-disabled,
  .chat-message-box.is-disabled:disabled,
  textarea:disabled,
  textarea:disabled::placeholder {
    background-color: $body-bg-color !important;
    color: $disabled-color-base !important;
    opacity: 1 !important;
  }
}

.fw-normal {
  font-weight: $fw-normal !important;
}
.fw-md {
  font-weight: $fw-md !important;
}
.fw-bold {
  font-weight: $fw-bold !important;
}
.input-textarea {
  min-height: 32px;
  height: 71px;
}
.pointer-events-none {
  pointer-events: none !important;
}
.pointer-events-all {
  pointer-events: all !important;
}
.nowrap {
  white-space: nowrap;
}
.bg-white {
  background-color: white;
}
.cell-desc-container {
  padding: 16px;
  .cell-desc {
    word-break: break-word;
    white-space: normal;
    display: block;
    color: $text-secondary;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 1200px) {
  .breadcrumb-container {
    padding: 8px;
    .els-header__title {
      font-size: $font-xxl;
    }
  }
}
